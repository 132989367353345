import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import { parse } from 'query-string';

import valueGet from './ConfigComponents/common/valueGet';
import { getTemplateWidgets } from '../../action';
import SelectDropDown from '../../../../../common/components/Select';
import { Button, CheckBox } from '../../../../../common/components';
import { Label, Col, Row } from 'reactstrap';

const PAGE_SIZE = 200;

const _widgetList = [''];

const _valueGet = (object) => {
  return Object.entries(object).reduce((memo, [key, value]) => {
    return {
      ...memo,
      [key]: valueGet(value)
    };
  }, {});
};

const WidgetSelect = (_props) => {
  const { getTemplateWidgets, ...props } = _props;

  var [widgetList, setWidgetList] = useState(_widgetList);
  const location = useLocation();

  let assetLocationId = parse(location.search).assetId;

  useEffect(() => {
    getTemplateWidgets({
      template_id: props.dashobardId,
      page_no: 1,
      page_size: PAGE_SIZE
    });
  }, [props.dashobardId, getTemplateWidgets]);

  useEffect(() => {
    if (Array.isArray(props.value) && props.value.length > 0) {
      setWidgetList(props.value);
    }
  }, [props.value]);

  const editWidgetConfiguration = (widget) => {
    props.history.push({
      pathname: `/Templates/WidgetBuilder/${props.dashobardId}/${widget.widget_id.id}?assetId=${assetLocationId}&edit_id=${widget.id}`,
      state: { widget: widget }
    });
  };

  return (
    <div className='w-100'>
      <Row className='mb-2'>
        <Col>
          <CheckBox
            size='lg'
            label='Network Insights'
            checked={props.networkInsights}
            checkboxClicked={(networkInsights) => {
              props.onChange(
                _valueGet({
                  networkInsights,
                  ...(networkInsights && {
                    showAllInsights: false,
                    gropu_widget_select: _widgetList
                  })
                }),
                false,
                ' '
              );
            }}
            className='ml-3'
          />
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col>
          <CheckBox
            size='lg'
            label='Show All Insights'
            checked={props.showAllInsights}
            checkboxClicked={(showAllInsights) => {
              props.onChange(
                _valueGet({
                  showAllInsights,
                  ...(showAllInsights && {
                    networkInsights: false,
                    gropu_widget_select: _widgetList
                  })
                }),
                false,
                ' '
              );
            }}
            className='ml-3'
          />
        </Col>
      </Row>

      <Col
        className={`select-dropdown custom-select-parameter ${
          props.networkInsights || props.showAllInsights ? 'disabled' : ''
        }`}
        xl={props.width ? props.width : 12}
      >
        <Row>
          <Col>
            <div className='add-widget-btn'>
              <Button
                className='buttonFill'
                buttonType='primary'
                size='sm'
                buttonClick={() => {
                  let oldWidgetList = [...widgetList];

                  oldWidgetList.push('');

                  setWidgetList([...oldWidgetList]);
                }}
                innerContent='Add Widget'
              />
            </div>
          </Col>
        </Row>

        {widgetList.map((item, i) => {
          return (
            <Row key={i} className='mb-3'>
              <span
                className='text-danger delete'
                onClick={() => {
                  let oldWidgetList = [...widgetList];

                  oldWidgetList.splice(i, 1);

                  setWidgetList([...oldWidgetList]);

                  props.onChange([...oldWidgetList], 'groupWidgets');
                }}
              >
                Delete
              </span>

              <span
                className='text-edit edit'
                onClick={() => {
                  let a = props.widgetsData.filter((elem) => {
                    return elem.id === item;
                  })[0];
                  editWidgetConfiguration(a);
                }}
              >
                Edit
              </span>

              <Col xl={12}>
                <Label for={props.name} className='label'>
                  Select Widget
                  {props.required === true ? (
                    <span className='text-danger'> * </span>
                  ) : null}
                </Label>

                <SelectDropDown
                  name='widget'
                  value={
                    props.widgetsData
                      .filter((elem) => {
                        return elem.id === item;
                      })
                      .map((el) => {
                        el.label =
                          el.config && el.config.widget_name
                            ? el.config.widget_name
                            : '';
                        el.value = el.id;
                        return el;
                      })[0]
                  }
                  onChange={(e) => {
                    let oldWidgetList = [...widgetList];
                    oldWidgetList[i] = e.id;
                    setWidgetList([...oldWidgetList]);
                    props.onChange(
                      [...oldWidgetList],
                      oldWidgetList === 0,
                      'groupWidgets'
                    );
                  }}
                  options={props.widgetsData
                    .filter((w) => {
                      return !widgetList.includes(w.id);
                    })
                    .map((a) => {
                      a.label =
                        a.config && a.config.widget_name
                          ? a.config.widget_name
                          : '';
                      a.value = a.id;
                      return a;
                    })}
                  placeholder={props.placeholder}
                  isMulti={false}
                />
              </Col>
            </Row>
          );
        })}
      </Col>
    </div>
  );
};

const mapStateToProps = (state) => {
  let widgetsData = state.getTemplateWidgets.data;

  let ignoredWidgets = ['Group', 'Container'];

  let filterdWidgetsData = widgetsData.filter((wid) => {
    const type = wid.widget_id && wid.widget_id.type;

    return !ignoredWidgets.includes(type);
  });

  return {
    widgetsData: filterdWidgetsData
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getTemplateWidgets
  })(WidgetSelect)
);
